export const COMMON_APP_SET = 'commonAppSet'
export const COMMON_PANEL_NOTIFICATIONS_SET = 'commonPanelNotificationSet'
export const COMMON_SET_VERSION = 'commonSetVersion'
export const COMMON_SET_CURRENCY = 'commonSetCurrency'
export const COMMON_SET_POPUP_VISIBLE = 'commonSetPopupVisible'
export const COMMON_MENU_CHANGE = 'commonMenuChange'
export const COMMON_FOOTER_BAR = 'commonFooterBar'
export const COMMON_MOBILE_MENU = 'commonMobileMenu'

export const COMMON_CLEAR_CACHE = 'commonClearCache'

export const BONUS_SET = 'bonusSet'

export const PROJECT_SET = 'projectSet'
export const PROJECT_ROLE_SET = 'projectRoleSet'

export const DNS_DOMAINS_SET = 'dnsDomainsSet'
export const DNS_DOMAINS_EXPIRED_SET = 'dnsDomainsExpiredSet'
export const DNS_TTL_SET = 'dnsTtlSet'

export const VSTACK_SERVERS_SET = 'vstackServersSet'
export const VSTACK_SERVERS_EXPIRED_SET = 'vstackServersExpiredSet'
export const VSTACK_CALCULATOR_SET = 'vstackCalculatorSet'

export const VSTACK_NETWORKS_SET = 'vstackNetworksSet'
export const VSTACK_NETWORKS_EXPIRED_SET = 'vstackNetworksExpiredSet'

export const VMWARE_SERVER_CALCULATOR_SET = 'vmwareServerCalculatorSet'
export const VMWARE_SERVERS_EXPIRED_SET = 'vmwareServersExpiredSet'
export const VMWARE_SERVERS_SET = 'vmwareServersSet'
export const VMWARE_NETWORK_CALCULATOR_SET = 'vmwareNetworkCalculatorSet'
export const VMWARE_NETWORKS_EXPIRED_SET = 'vmwareNetworksExpiredSet'
export const VMWARE_NETWORKS_SET = 'vmwareNetworksSet'

export const STORAGE_DETAIL_SET = 'storageDetailSet'
export const STORAGE_TOKEN_SET = 'storageTokenSet'
export const STORAGE_DETAIL_EXPIRED_SET = 'storageDetailExpiredSet'
export const STORAGE_FILE_COPY_SET = 'storageFileCopySet'
export const STORAGE_FILE_MOVE_SET = 'storageFileMoveSet'

export const SUPPORT_SIMPLEONE_SET_LINK = 'supportSimpleOneSetLink'

export const K8S_CLUSTER_CALCULATOR_SET = 'k8sClusterCalculatorSet'

export const THEME_SET = 'themeSet'

export const BILLING_SCRIPT_SEON_STATE_SET = 'billingScriptSeonStateSet'
export const BILLING_SCRIPT_CLOUD_PAYMENTS_STATE_SET = 'billingScriptCloudPaymentsStateSet'
export const BILLING_SCRIPT_BEPAID_STATE_SET = 'billingScriptBepaidStateSet'

export const CONFIGURED_SERVER_CALCULATOR_SET = 'configuredServerCalculatorSet'
export const CONFIGURED_SERVERS_SET = 'configuredServersSet'

export const CDN_CUSTOM_MODE_SET = 'cdnCustomModeSet'

export const UNREAD_SUPPORT_SET = 'unreadSupportSet'