import ApiService from '@/api'

import { VSTACK_SERVERS_IS_EXPIRED, VSTACK_NETWORKS_IS_EXPIRED } from '../getters.type'
import { VSTACK_SERVERS_FETCH,
  VSTACK_SERVER_CREATE,
  VSTACK_SERVER_DELETE,
  VSTACK_SERVER_EDIT,
  VSTACK_SERVER_FETCH,
  VSTACK_SERVER_ORDER_FETCH,
  VSTACK_SERVER_CALCULATOR_FETCH,
  VSTACK_SSH_CREATE,
  VSTACK_TASK_FETCH,
  VSTACK_SERVERS_ITEM_FETCH,
  VSTACK_SERVER_CONFIGURATION_CHANGE,
  VSTACK_SERVER_POWER_ACTION,
  VSTACK_SERVER_DISK_CREATE,
  VSTACK_SERVER_DISK_EDIT,
  VSTACK_SERVER_DISK_DELETE,
  VSTACK_SERVER_LICENSES_AVAILABALE_FETCH,
  VSTACK_SERVER_LICENSES_ADD,
  VSTACK_SERVER_NETWORKS_AVAILABALE_FETCH,
  VSTACK_SERVER_BOOT_MEDIA_CHANGE,
  VSTACK_NETWORK_SERVER_ADD,
  VSTACK_NETWORK_SERVER_DETELE,
  VSTACK_NETWORKS_FETCH,
  VSTACK_NETWORK_CALCULATOR_FETCH,
  VSTACK_NETWORK_CREATE,
  VSTACK_NETWORK_FETCH,
  VSTACK_NETWORK_CONNECT,
  VSTACK_NETWORK_DELETE,
  VSTACK_SERVER_WEBCONSOLE_FETCH,
  VSTACK_SERVER_ORDER_CANCEL,
  VSTACK_NETWORK_EDIT_NAME_DESCRIPTION,
  VSTACK_SERVER_SNAPSHOT_CREATE,
  VSTACK_SERVER_SNAPSHOT_RESTORE,
  VSTACK_SERVER_SNAPSHOT_DELETE,
  VSTACK_SERVER_NIC_EDIT,
  VSTACK_NETWORK_SERVER_SHARED_ADD,
  VSTACK_SERVER_NETWORK_DISCONNECT,
  VSTACK_SERVER_TAG_REMOVE,
  VSTACK_NETWORK_TAG_ADD,
  VSTACK_NETWORK_TAG_REMOVE,
  VSTACK_SERVER_TAG_ADD,
  VSTACK_SERVER_PERFORMANCE,
  VSTACK_EDGES_FETCH,
  VSTACK_EDGE_CALCULAOTR_FETCH,
  VSTACK_EDGE_CREATE,
  VSTACK_EDGE_TAG_ADD,
  VSTACK_EDGE_TAG_REMOVE,
  VSTACK_EDGE_FETCH,
  VSTACK_EDGE_DELETE,
  VSTACK_EDGE_NAME,
  VSTACK_EDGE_BANDWIDTH,
  VSTACK_EDGE_FIREWALL_RULES,
  VSTACK_EDGE_NAT_RULES,
  VSTACK_EDGE_NETWORK_CONNECT,
  VSTACK_EDGE_NETWORK_DISCONNECT,
  VSTACK_EDGE_ACTIONS,
  VSTACK_SERVER_GET_REASON,
  VSTACK_SERVER_SEND_REASON,
  VSTACK_GROUP_ADD,
  VSTACK_GROUP_DETAIL,
  VSTACK_GROUP_DELETE,
} from '../actions.type'

import { VSTACK_SERVERS_SET, VSTACK_SERVERS_EXPIRED_SET, VSTACK_NETWORKS_SET, VSTACK_NETWORKS_EXPIRED_SET, VSTACK_CALCULATOR_SET, COMMON_CLEAR_CACHE } from '../mutations.type'

const state = {
  calculator: {
    data: null,
    expire: null,
  },
  servers: {
    data: null,
    expire: null,
  },
  networks: {
    data: null,
    expire: null,
  },
  errors: null,
  loading: false,
}

// getters
const getters = {
  [VSTACK_SERVERS_IS_EXPIRED]: (state) => () => !state.servers.expire || (state.servers.expire && state.servers.expire < new Date().getTime()),
  [VSTACK_NETWORKS_IS_EXPIRED]: (state) => () => !state.networks.expire || (state.networks.expire && state.networks.expire < new Date().getTime()),
}

// actions
const actions = {
  [VSTACK_SERVER_CALCULATOR_FETCH] (context) {
    if (state.calculator.expire && state.calculator.expire > new Date().getTime()) {
      return new Promise((resolve) => {
        resolve(state.calculator.data)
      })
    } else {
      return ApiService.get('/app-api/vstack/servers/create/json')
        .then(({ data }) => {
          context.commit(VSTACK_CALCULATOR_SET, data)
          return data
        })
    }
  },
  [VSTACK_SERVERS_FETCH] (context) {
    if (state.servers.expire && state.servers.expire > new Date().getTime()) {
      return new Promise((resolve) => {
        resolve(state.servers.data)
      })
    } else {
      return ApiService.get('/app-api/vstack/servers/list/json')
        .then(({ data }) => {
          context.commit(VSTACK_SERVERS_SET, data)
          return data
        })
    }
  },
  [VSTACK_SERVER_CREATE] (context, model) {
    context.commit(VSTACK_SERVERS_EXPIRED_SET)
    return ApiService.post('/app-api/vstack/servers', model)
      .then(({ data }) => {
        return data
      })
  },
  [VSTACK_SSH_CREATE] (context, params) {
    return ApiService.post('/app-api/ssh-keys', params)
      .then(({ data }) => {
        return data
      })
  },
  [VSTACK_SERVER_DELETE] (context, params) {
    context.commit(VSTACK_SERVERS_EXPIRED_SET)
    return ApiService.delete(`/app-api/vstack/servers/${params.serverId}`)
      .then(({ data }) => {
        return data
      })
  },
  [VSTACK_SERVER_FETCH] (context, params) {
    return ApiService.get(`/app-api/vstack/servers/${params.serverId}/json`)
      .then(({ data }) => {
        return data
      })
  },
  [VSTACK_SERVER_PERFORMANCE] (context, params) {
    return ApiService.query(`/app-api/vstack/servers/${params.serverId}/performance/json?metric=${params.key}&interval=${params.interval}`)
      .then(({ data }) => {
        return data
      })
  },
  [VSTACK_SERVER_WEBCONSOLE_FETCH] (context, params) {
    return ApiService.post(`/app-api/vstack/servers/${params.serverId}/console/json`)
      .then(({ data }) => {
        return data
      })
  },
  [VSTACK_SERVER_ORDER_FETCH] (context, orderId) {
    return ApiService.get(`/app-api/vstack/servers/order/${orderId}/json`)
      .then(({ data }) => {
        return data
      })
  },
  [VSTACK_SERVER_ORDER_CANCEL] (context, orderId) {
    context.commit(VSTACK_SERVERS_EXPIRED_SET)
    return ApiService.delete(`/app-api/vstack/servers/order/${orderId}`)
      .then(({ data }) => {
        return data
      })
  },
  [VSTACK_TASK_FETCH] (context, params) {
    return ApiService.get(`/app-api/vstack/tasks/${params.taskId}/json`)
      .then(({ data }) => {
        context.commit(VSTACK_SERVERS_EXPIRED_SET)
        context.commit(VSTACK_NETWORKS_EXPIRED_SET)
        return data
      })
  },
  [VSTACK_SERVERS_ITEM_FETCH] (context, orderId) {
    return ApiService.get(`/app-api/vstack/servers/list/item/${orderId}/json`)
      .then(({ data }) => {
        return data
      })
  },
  [VSTACK_SERVER_CONFIGURATION_CHANGE] (context, params) {
    context.commit(VSTACK_SERVERS_EXPIRED_SET)
    return ApiService.put(`/app-api/vstack/servers/${params.serverId}`, params.model)
      .then(({ data }) => {
        return data
      })
  },
  [VSTACK_SERVER_POWER_ACTION] (context, params) {
    context.commit(VSTACK_SERVERS_EXPIRED_SET)
    return ApiService.post(`/app-api/vstack/servers/${params.serverId}/${params.action}`, {})
      .then(({ data }) => {
        return data
      })
  },
  [VSTACK_SERVER_DISK_CREATE] (context, params) {
    context.commit(VSTACK_SERVERS_EXPIRED_SET)
    return ApiService.post(`/app-api/vstack/servers/${params.serverId}/disks`, params.model)
      .then(({ data }) => {
        return data
      })
  },
  [VSTACK_SERVER_DISK_EDIT] (context, params) {
    context.commit(VSTACK_SERVERS_EXPIRED_SET)
    return ApiService.put(`/app-api/vstack/servers/${params.serverId}/disks/${params.diskId}`, params.model)
      .then(({ data }) => {
        return data
      })
  },
  [VSTACK_SERVER_DISK_DELETE] (context, params) {
    context.commit(VSTACK_SERVERS_EXPIRED_SET)
    return ApiService.delete(`/app-api/vstack/servers/${params.serverId}/disks/${params.diskId}`)
      .then(({ data }) => {
        return data
      })
  },
  [VSTACK_SERVER_SNAPSHOT_CREATE] (context, params) {
    return ApiService.post(`/app-api/vstack/servers/${params.serverId}/snapshots`, params.model)
      .then(({ data }) => {
        context.commit(VSTACK_SERVERS_EXPIRED_SET)
        return data
      })
  },
  [VSTACK_SERVER_SNAPSHOT_RESTORE] (context, params) {
    return ApiService.post(`/app-api/vstack/servers/${params.serverId}/snapshots/${params.snapshotId}/restore`)
      .then(({ data }) => {
        context.commit(VSTACK_SERVERS_EXPIRED_SET)
        return data
      })
  },
  [VSTACK_SERVER_SNAPSHOT_DELETE] (context, params) {
    return ApiService.delete(`/app-api/vstack/servers/${params.serverId}/snapshots/${params.snapshotId}`)
      .then(({ data }) => {
        context.commit(VSTACK_SERVERS_EXPIRED_SET)
        return data
      })
  },
  [VSTACK_SERVER_LICENSES_AVAILABALE_FETCH] (context, params) {
    return ApiService.get(`/app-api/vstack/servers/${params.serverId}/available-licenses/json`)
      .then(({ data }) => {
        return data
      })
  },
  [VSTACK_SERVER_LICENSES_ADD] (context, params) {
    context.commit(VSTACK_SERVERS_EXPIRED_SET)
    return ApiService.post(`/app-api/vstack/servers/${params.serverId}/license`, params.model)
      .then(({ data }) => {
        return data
      })
  },
  [VSTACK_SERVER_NIC_EDIT] (context, params) {
    return ApiService.put(`/app-api/vstack/servers/${params.serverId}/nics/${params.nicId}`, params.model)
      .then(({ data }) => {
        return data
      })
  },
  [VSTACK_SERVER_NETWORKS_AVAILABALE_FETCH] (context, params) {
    return ApiService.get(`/app-api/vstack/servers/${params.serverId}/networks/available`)
      .then(({ data }) => {
        return data
      })
  },
  [VSTACK_SERVER_NETWORK_DISCONNECT] (context, params) {
    context.commit(VSTACK_SERVERS_EXPIRED_SET)
    context.commit(VSTACK_NETWORKS_EXPIRED_SET)
    return ApiService.delete(`/app-api/vstack/servers/${params.serverId}/nics/${params.nicId}`)
      .then(({ data }) => {
        return data
      })
  },
  [VSTACK_SERVER_BOOT_MEDIA_CHANGE] (context, params) {
    return ApiService.post(`/app-api/vstack/servers/${params.serverId}/active-boot-medium`, params.model)
      .then(({ data }) => {
        return data
      })
  },
  [VSTACK_SERVER_TAG_ADD] (context, params) {
    context.commit(VSTACK_SERVERS_EXPIRED_SET)
    return ApiService.post(`/app-api/vstack/servers/${params.serverId}/tags?tag=${params.tag}`)
      .then(({ data }) => {
        return data
      })
  },
  [VSTACK_SERVER_TAG_REMOVE] (context, params) {
    context.commit(VSTACK_SERVERS_EXPIRED_SET)
    return ApiService.delete(`/app-api/vstack/servers/${params.serverId}/tags?tag=${params.tag}`)
      .then(({ data }) => {
        return data
      })
  },
  [VSTACK_NETWORK_SERVER_ADD] (context, params) {
    context.commit(VSTACK_SERVERS_EXPIRED_SET)
    context.commit(VSTACK_NETWORKS_EXPIRED_SET)
    return ApiService.post(`/app-api/vstack/networks/${params.networkId}/servers/${params.serverId}`, params.model)
      .then(({ data }) => {
        return data
      })
  },
  [VSTACK_NETWORK_SERVER_SHARED_ADD] (context, params) {
    context.commit(VSTACK_SERVERS_EXPIRED_SET)
    context.commit(VSTACK_NETWORKS_EXPIRED_SET)
    return ApiService.post(`/app-api/vstack/networks/shared/servers/${params.serverId}`, {})
      .then(({ data }) => {
        return data
      })
  },
  [VSTACK_NETWORK_SERVER_DETELE] (context, params) {
    context.commit(VSTACK_SERVERS_EXPIRED_SET)
    context.commit(VSTACK_NETWORKS_EXPIRED_SET)
    return ApiService.delete(`/app-api/vstack/networks/${params.networkId}/servers/${params.serverId}`)
      .then(({ data }) => {
        return data
      })
  },
  [VSTACK_NETWORKS_FETCH] (context) {
    return ApiService.get('/app-api/vstack/networks/list/json')
      .then(({ data }) => {
        context.commit(VSTACK_NETWORKS_SET, data)
        return data
      })
  },
  [VSTACK_NETWORK_CALCULATOR_FETCH] () {
    return ApiService.get('/app-api/vstack/networks/create/json')
      .then(({ data }) => {
        return data
      })
  },
  [VSTACK_NETWORK_CREATE] (context, params) {
    context.commit(VSTACK_NETWORKS_EXPIRED_SET)
    return ApiService.post(`/app-api/vstack/networks`, params.model)
      .then(({ data }) => {
        return data
      })
  },
  [VSTACK_NETWORK_FETCH] (context, params) {
    return ApiService.get(`/app-api/vstack/networks/${params.networkId}/json`)
      .then(({ data }) => {
        return data
      })
  },
  [VSTACK_NETWORK_EDIT_NAME_DESCRIPTION] (context, params) {
    context.commit(VSTACK_NETWORKS_EXPIRED_SET)
    return ApiService.put(`/app-api/vstack/networks/${params.networkId}`, params.model)
      .then(({ data }) => {
        return data
      })
  },
  [VSTACK_NETWORK_CONNECT] (context, params) {
    context.commit(VSTACK_NETWORKS_EXPIRED_SET)
    context.commit(VSTACK_SERVERS_EXPIRED_SET)
    return ApiService.post(`/app-api/vstack/networks/${params.networkId}/connect`, params.model)
      .then(({ data }) => {
        return data
      })
  },
  [VSTACK_NETWORK_DELETE] (context, params) {
    context.commit(VSTACK_NETWORKS_EXPIRED_SET)
    return ApiService.delete(`/app-api/vstack/networks/${params.networkId}`)
      .then(({ data }) => {
        return data
      })
  },
  [VSTACK_NETWORK_TAG_ADD] (context, params) {
    context.commit(VSTACK_NETWORKS_EXPIRED_SET)
    return ApiService.post(`/app-api/vstack/networks/${params.networkId}/tags?tag=${params.tag}`)
      .then(({ data }) => {
        return data
      })
  },
  [VSTACK_NETWORK_TAG_REMOVE] (context, params) {
    context.commit(VSTACK_NETWORKS_EXPIRED_SET)
    return ApiService.delete(`/app-api/vstack/networks/${params.networkId}/tags?tag=${params.tag}`)
      .then(({ data }) => {
        return data
      })
  },
  [VSTACK_EDGES_FETCH] () {
    return ApiService.get(`/app-api/vstack/edges/list/json`)
      .then(({ data }) => {
        return data
      })
  },
  [VSTACK_EDGE_CALCULAOTR_FETCH] () {
    return ApiService.get(`/app-api/vstack/edges/create/json`)
      .then(({ data }) => {
        return data
      })
  },
  [VSTACK_EDGE_CREATE] (context, model) {
    return ApiService.post(`/app-api/vstack/edges/create`, model)
      .then(({ data }) => {
        return data
      })
  },
  [VSTACK_EDGE_TAG_ADD] (context, params) {
    return ApiService.post(`/app-api/vstack/edges/${params.edgeId}/tags?tag=${params.tag}`)
      .then(({ data }) => {
        return data
      })
  },
  [VSTACK_EDGE_TAG_REMOVE] (context, params) {
    return ApiService.delete(`/app-api/vstack/edges/${params.edgeId}/tags?tag=${params.tag}`)
      .then(({ data }) => {
        return data
      })
  },
  [VSTACK_EDGE_FETCH] (context, edgeId) {
    return ApiService.get(`/app-api/vstack/edges/${edgeId}/json`)
      .then(({ data }) => {
        return data
      })
  },
  [VSTACK_EDGE_DELETE] (context, params) {
    return ApiService.delete(`/app-api/vstack/edges/${params.edgeId}`)
      .then(({ data }) => {
        return data
      })
  },
  [VSTACK_EDGE_NAME] (context, params) {
    return ApiService.put(`/app-api/vstack/edges/${params.edgeId}/name`, params.model)
      .then(({ data }) => {
        return data
      })
  },
  [VSTACK_EDGE_BANDWIDTH] (context, params) {
    return ApiService.put(`/app-api/vstack/edges/${params.edgeId}/bandwidth`, params.model)
      .then(({ data }) => {
        return data
      })
  },
  [VSTACK_EDGE_FIREWALL_RULES] (context, params) {
    return ApiService.put(`/app-api/vstack/edges/${params.edgeId}/firewall`, params.model)
      .then(({ data }) => {
        return data
      })
  },
  [VSTACK_EDGE_NAT_RULES] (context, params) {
    return ApiService.put(`/app-api/vstack/edges/${params.edgeId}/nat`, params.model)
      .then(({ data }) => {
        return data
      })
  },
  [VSTACK_EDGE_ACTIONS] (context, params) {
    return ApiService.post(`/app-api/vstack/edges/${params.edgeId}/${params.action}`, {})
      .then(({ data }) => {
        return data
      })
  },
  [VSTACK_EDGE_NETWORK_CONNECT] (context, params) {
    return ApiService.post(`/app-api/vstack/edges/${params.edgeId}/connect-network`, params.model)
      .then(({ data }) => {
        return data
      })
  },
  [VSTACK_EDGE_NETWORK_DISCONNECT] (context, params) {
    return ApiService.post(`/app-api/vstack/edges/${params.edgeId}/disconnect-network`, params.model)
      .then(({ data }) => {
        return data
      })
  },
  [VSTACK_SERVER_GET_REASON] () {
    return ApiService.get(`/app-api/server-deletion-reasons`)
      .then(({ data }) => {
        return data
      })
  },
  [VSTACK_SERVER_SEND_REASON] (context, params) {
    return ApiService.post(`/app-api/server-deletion-reasons`, params)
      .then(({ data }) => {
        return data
      })
  },
  [VSTACK_SERVER_EDIT] (context, params) {
    return ApiService.post(`/app-api/vstack/servers/${params.serverId}/rename`, params.model)
      .then(({ data }) => {
        return data
      })
  },
  [VSTACK_GROUP_ADD] (context, params) {
    return ApiService.post(`/app-api/vstack/affinity-groups`, params)
      .then(({ data }) => {
        return data
      })
  },
  [VSTACK_GROUP_DETAIL] (context, id) {
    return ApiService.get(`/app-api/vstack/affinity-groups/${id}`)
      .then(({ data }) => {
        return data
      })
  },
  [VSTACK_GROUP_DELETE] (context, id) {
    return ApiService.delete(`/app-api/vstack/affinity-groups/${id}`)
      .then(({ data }) => {
        return data
      })
  },
}

// mutations
const mutations = {
  [VSTACK_SERVERS_SET] (state, servers) {
    state.servers.data = servers
    state.servers.expire = new Date(new Date().getTime() + parseInt(process.env.VUE_APP_LIST_EXPIRE_MS)).getTime()
  },
  [VSTACK_SERVERS_EXPIRED_SET] (state) {
    state.servers.expire = null
  },
  [VSTACK_CALCULATOR_SET] (state, calculator) {
    state.calculator.data = calculator
    state.calculator.expire = new Date(new Date().getTime() + parseInt(process.env.VUE_APP_CALCULATOR_EXPIRE_MS)).getTime()
  },
  [VSTACK_NETWORKS_SET] (state, networks) {
    state.networks.data = networks
    state.networks.expire = new Date(new Date().getTime() + parseInt(process.env.VUE_APP_LIST_EXPIRE_MS)).getTime()
  },
  [VSTACK_NETWORKS_EXPIRED_SET] (state) {
    state.networks.expire = null
  },
  [COMMON_CLEAR_CACHE] (state) {
    state.calculator.expire = null
    state.networks.expire = null
    state.servers.expire = null
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
