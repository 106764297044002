import { createApp } from 'vue'
import Vuex from 'vuex'

import project from './modules/project'

import theme from './modules/theme'

import vstack from './modules/vstack'
import dns from './modules/dns'
import unoc from './modules/unoc'
import account from './modules/account'
import billing from './modules/billing'
import vcd from './modules/vcd'
import managed from './modules/managed'
import support from './modules/support'
import storage from './modules/storage'
import tags from './modules/tags'
import vmware from './modules/vmware'
import documentFlow from './modules/document-flow'
import referral from './modules/referral'
import k8s from './modules/k8s'
import ssl from './modules/ssl'
import feedback from './modules/feedback'
import help from './modules/help'
import bonus from './modules/bonus'
import configured from './modules/configured'
import cdn from './modules/cdn'
import itglobal from './modules/itglobal'

import i18n from '@/i18n.js'
import { COMMON_APP_SETTINGS, COMMON_DEFAULT_FEATURE, COMMON_NOTIFICATIONS_FETCH, COMMON_NOTIFICATION_CLOSE, SUPPORT_SIMPLEONE_GET_DATA } from './actions.type'
import { SIMPLEONE_GET_LINK_PORTAL, SIMPLEONE_GET_LINK_SUPPORT, SIMPLEONE_GET_LINK_PORTAL_ESSENCE, MOBILE_MENU_OPENED } from './getters.type'
import { COMMON_SET_VERSION, COMMON_PANEL_NOTIFICATIONS_SET, COMMON_SET_POPUP_VISIBLE, COMMON_APP_SET, PROJECT_SET, THEME_SET, COMMON_MENU_CHANGE, COMMON_CLEAR_CACHE, COMMON_SET_CURRENCY, SUPPORT_SIMPLEONE_SET_LINK, COMMON_FOOTER_BAR, COMMON_MOBILE_MENU, CDN_CUSTOM_MODE_SET } from './mutations.type'

import ApiService from '@/api'
const $t = (key) => i18n.global.t(key)
const app = createApp()
app.use(Vuex)

const state = {
  app: {
    data: null,
    expire: null,
  },
  version: null,
  currency: '',
  notifications: [],
  popupIsVisible: false,
  menu: {
    isOpen: true,
    mobile: false,
  },
  footerRightBar: true,
  simpleOneSupportLink: '',
  simpleOneSupportLinkEssence: '',
  themeDefault: '',
  financesActive: false,
  loaded: false,
}

// getters
const getters = {
  [SIMPLEONE_GET_LINK_PORTAL] (state) {
    return state.simpleOneSupportLink
  },
  [SIMPLEONE_GET_LINK_PORTAL_ESSENCE] (state) {
    return state.simpleOneSupportLinkEssence
  },
  [SIMPLEONE_GET_LINK_SUPPORT] (state) {
    return state.simpleOneSupportLink + 'portal/record?table_name=itsm_inquiry&view=Service%20Catalog'
  },
  [MOBILE_MENU_OPENED] (state) {
    return state.menu.mobile
  },
}

// actions
const actions = {
  [COMMON_APP_SETTINGS] (context, props) {
    if ((state.project.current && props.pid === state.project.current.Id) && !props.isForce && state.app.expire && state.app.expire > new Date().getTime()) {
      return new Promise((resolve) => {
        resolve(state.app.data)
      })
    } else {
      return ApiService.query('/app-api/app', { params: { pid: props.pid } })
        .then(({ data }) => {
          context.commit(COMMON_CLEAR_CACHE)
          context.commit(PROJECT_SET, data.Project)
          context.commit(COMMON_APP_SET, data)

          let cdnFeature = data.Project.Features.find(f => f.Id === 'Cdn')
          if (cdnFeature) {
            context.commit(CDN_CUSTOM_MODE_SET, cdnFeature?.FeatureOptions?.CustomModeAllowed)
          }
          state.financesActive = !!data.Project.Features.find(f => f.Id === 'Finances')
          state.themeDefault = data.Theme
          state.isDarkTheme = data.IsDarkTheme
          state.partnerName = data.PartnerName

          context.commit(THEME_SET, { theme: data.Theme, isDark: data.IsDarkTheme })

          switch (data.Currency) {
            case 'BTC':
              context.commit(COMMON_SET_CURRENCY, $t('shared.currency.btc'))
              break
            case 'BYN':
              context.commit(COMMON_SET_CURRENCY, $t('shared.currency.byn'))
              break
            case 'EUR':
              context.commit(COMMON_SET_CURRENCY, $t('shared.currency.eur'))
              break
            case 'INR':
              context.commit(COMMON_SET_CURRENCY, $t('shared.currency.inr'))
              break
            case 'KZT':
              context.commit(COMMON_SET_CURRENCY, $t('shared.currency.kzt'))
              break
            case 'RUB':
              context.commit(COMMON_SET_CURRENCY, $t('shared.currency.rub'))
              break
            case 'TRY':
              context.commit(COMMON_SET_CURRENCY, $t('shared.currency.try'))
              break
            case 'USD':
              context.commit(COMMON_SET_CURRENCY, $t('shared.currency.usd'))
              break
            case 'CAD':
              context.commit(COMMON_SET_CURRENCY, $t('shared.currency.cad'))
              break
            case 'BRL':
              context.commit(COMMON_SET_CURRENCY, $t('shared.currency.brl'))
              break
            case 'AED':
              context.commit(COMMON_SET_CURRENCY, $t('shared.currency.aed'))
              break
            case 'UZS':
              context.commit(COMMON_SET_CURRENCY, $t('shared.currency.uzs'))
              break
          }
          state.loaded = true
          return data
        })
    }
  },
  [COMMON_DEFAULT_FEATURE] (context, ct) {
    return ApiService.query(`/app-api/app/default-feature`, { cancelToken: ct })
      .then(({ data }) => {
        return data
      })
  },
  [COMMON_NOTIFICATIONS_FETCH] (context) {
    return ApiService.get('/app-api/panel-notifications')
      .then(({ data }) => {
        context.commit(COMMON_PANEL_NOTIFICATIONS_SET, data)
        return data
      })
  },
  [COMMON_NOTIFICATION_CLOSE] (context, notificationId) {
    return ApiService.post(`/app-api/panel-notifications/${notificationId}/close`)
      .then(({ data }) => {
        return data
      })
  },
  [SUPPORT_SIMPLEONE_GET_DATA] (context) {
    return ApiService.get('/app-api/simpleone-support/config')
      .then(({ data }) => {
        context.commit(SUPPORT_SIMPLEONE_SET_LINK, data)
      })
  },
}

// mutations
const mutations = {
  [COMMON_APP_SET] (state, app) {
    state.app.data = app
    state.app.expire = new Date(new Date().getTime() + parseInt(process.env.VUE_APP_APP_EXIRE_MS)).getTime()
  },
  [COMMON_PANEL_NOTIFICATIONS_SET] (state, notifications) {
    state.notifications = notifications
  },
  [COMMON_SET_VERSION] (state, version) {
    state.version = version
  },
  [COMMON_SET_POPUP_VISIBLE] (state, isVisible) {
    state.popupIsVisible = isVisible
  },
  [COMMON_MENU_CHANGE] (state, isOpen) {
    state.menu.isOpen = isOpen
  },
  [COMMON_MOBILE_MENU] (state, isOpen) {
    state.menu.mobile = isOpen
  },
  [COMMON_FOOTER_BAR] (state, switched) {
    state.footerRightBar = switched
  },
  [COMMON_SET_CURRENCY] (state, currency) {
    state.currency = state.app.data.Currency === 'CAD' ? '$ CAD' : currency
  },
  [SUPPORT_SIMPLEONE_SET_LINK] (state, payload) {
    state.simpleOneSupportLink = payload.SupportPortalUrl
    state.simpleOneSupportLinkEssence = payload.Essence
  },
}

const store = new Vuex.Store({
  state,
  getters,
  actions,
  mutations,
  modules: {
    project,
    theme,
    vstack,
    dns,
    unoc,
    account,
    billing,
    vcd,
    managed,
    support,
    storage,
    tags,
    vmware,
    documentFlow,
    referral,
    k8s,
    ssl,
    feedback,
    help,
    bonus,
    configured,
    cdn,
    itglobal,
  },
})

if (module.hot) {
  // accept actions and mutations as hot modules
  module.hot.accept([
    './modules/project',
    './modules/theme',
    './modules/vstack',
    './modules/dns',
    './modules/unoc',
    './modules/account',
    './modules/billing',
    './modules/vcd',
    './modules/managed',
    './modules/support',
    './modules/storage',
    './modules/tags',
    './modules/vmware',
    './modules/document-flow',
    './modules/referral',
    './modules/k8s',
    './modules/ssl',
    './modules/feedback',
    './modules/help',
    './modules/bonus',
    './modules/configured',
    './modules/cdn',
    './modules/itglobal',
  ], () => {
    // require the updated modules
    // have to add .default here due to babel 6 module output
    const newModuleProject = require('./modules/project').default
    const newModuleTheme = require('./modules/theme').default
    const newModuleVstack = require('./modules/vstack').default
    const newModuleDns = require('./modules/dns').default
    const newModuleUnoc = require('./modules/unoc').default
    const newModuleAccount = require('./modules/account').default
    const newModuleBilling = require('./modules/billing').default
    const newModuleVcd = require('./modules/vcd').default
    const newModuleManaged = require('./modules/managed').default
    const newModuleSupport = require('./modules/support').default
    const newModuleStorage = require('./modules/storage').default
    const newModuleTags = require('./modules/tags').default
    const newModuleVmware = require('./modules/vmware').default
    const newModuleDocumentFlow = require('./modules/document-flow').default
    const newModuleReferral = require('./modules/referral').default
    const newModuleK8s = require('./modules/k8s').default
    const newModuleSsl = require('./modules/ssl').default
    const newModuleFeedback = require('./modules/feedback').default
    const newModuleHelp = require('./modules/help').default
    const newModuleBonus = require('./modules/bonus').default
    const newModuleConfigured = require('./modules/configured').default
    const newModuleCdn = require('./modules/cdn').default
    const newModuleItglobal = require('./modules/itglobal').default
    // swap in the new modules and mutations
    store.hotUpdate({
      modules: {
        project: newModuleProject,
        theme: newModuleTheme,
        vstack: newModuleVstack,
        dns: newModuleDns,
        unoc: newModuleUnoc,
        account: newModuleAccount,
        billing: newModuleBilling,
        vcd: newModuleVcd,
        managed: newModuleManaged,
        support: newModuleSupport,
        storage: newModuleStorage,
        tags: newModuleTags,
        vmware: newModuleVmware,
        documentFlow: newModuleDocumentFlow,
        referral: newModuleReferral,
        k8s: newModuleK8s,
        ssl: newModuleSsl,
        feedback: newModuleFeedback,
        help: newModuleHelp,
        bonus: newModuleBonus,
        configured: newModuleConfigured,
        cdn: newModuleCdn,
        itglobal: newModuleItglobal,
      },
    })
  })
}

export default store
