<template>
  <div
    class="project-select"
    :class="{
      'project-select--menu-open': $store.state.menu.isOpen,
      'project-select--open': isOpen,
      'mobile': $store.state.menu.mobile,
    }"
  >
    <div
      ref="btnOpen"
      class="project-item project-item-current"
      :class="{ 'hidden' : !$store.state.menu.isOpen }"
      @click="switchOpen"
    >
      <img
        v-if="logoLoaded"
        :src="getLogo($store.state.project.current.LogoName)"
        class="project-item-logo"
      >
      <Loading
        v-else
      />
      <span class="project-item-name">{{ $store.state.project.current.Name }}</span>
      <span
        v-if="$store.state.menu.isOpen"
        class="project-item-current-arrow"
        :class="{ 'opened' : isOpen }"
        v-html="icons.MENU_ARROW"
      />
    </div>
    <div
      v-show="isOpen"
      class="project-select-menu"
      :class="{ 'hidden' : !$store.state.menu.isOpen }"
      @click="isOpen = false"
    >
      <PerfectScrollbar
        class="project-select-menu-ps"
        :options="{wheelPropagation: false}"
      >
        <div
          v-if="projectsActive.length"
          class="project-select-menu-list"
        >
          <router-link
            v-for="project in projectsActive"
            :key="project.Id"
            :to="`/?pid=${project.Id}`"
            class="project-item"
            :class="{ 'project-item-selected' : projectIsActive(project.Id) }"
          >
            <img
              :src="getLogo(project.LogoName)"
              class="project-item-logo"
            >
            <span class="project-item-name">{{ project.Name }}</span>
            <span
              v-if="showSettingsIcon(project)"
              class="project-item-settings-icon"
              @click.prevent.stop="toSettings"
              v-html="icons.PROJECT_SETTINGS"
            />
            <span
              v-if="projectIsActive(project.Id)"
              class="project-item-selected-icon"
            >
              <span
                v-html="icons.CHECKMARK"
              />
            </span>
          </router-link>
          <div
            v-if="projectsPending.length"
            class="project-pending"
          >
            {{ $t('projects.shared.project-select.pending') }}
          </div>
          <a
            v-for="project in projectsPending"
            :key="project.Id"
            class="project-item"
            @click="$emit('setProjectInvite', project)"
          >
            <img
              :src="project.LogoUrl"
              class="project-item-logo"
            >
            <span class="project-item-name">{{ project.Name }}</span>
          </a>
        </div>
      </PerfectScrollbar>
      <div
        v-if="showAddProjectBtn"
        class="project-select-menu-create"
      >
        <span
          class="project-item"
          @click="$emit('setProjectCreate')"
        >
          <span class="project-item-logo create-logo">+</span>
          <span class="project-item-name create-name">{{ $t('projects.shared.project-select.create') }}</span>
        </span>
      </div>
    </div>
    <BaseButton
      v-if="showAddMoneyBtn"
      class="mobile-add-money"
      size="small"
      reverse
      @click="toAddMoney"
    >
      {{ $t('layout.header.billing.add-money') }}
    </BaseButton>
  </div>
</template>

<script>
import { ICON_SETTINGS, ICON_MENU_ARROW } from '@/svg/common'
import { ICON_OK, ICON_PROJECT_SETTINGS } from '@/svg/icons'
import accountImages from '@/account-images'
import { PROJECT_CUSTOM_LOGO_URL } from '@/store/actions.type'
import BaseButton from '@/components/base/BaseButton.vue'
import { COMMON_MOBILE_MENU } from '@/store/mutations.type'
import feature from '@/feature'
import partner from '@/partner'

export default {
  components: { BaseButton },
  emits: ['setProjectInvite', 'setProjectCreate'],
  data () {
    return {
      isOpen: false,
      icons: {
        SETTINGS: ICON_SETTINGS,
        CHECKMARK: ICON_OK,
        MENU_ARROW: ICON_MENU_ARROW,
        PROJECT_SETTINGS: ICON_PROJECT_SETTINGS,
      },
      defaultLogos: accountImages.accountLogos,
      customLogoUrl: '',
      logoLoaded: false,
    }
  },
  computed: {
    projectsActive () {
      return this.$store.state.app.data.Projects.filter(x => !x.IsInvite)
    },
    projectsPending () {
      return this.$store.state.app.data.Projects.filter(x => x.Id !== this.$store.state.project.current.Id && x.IsInvite)
    },
    showAddProjectBtn () {
      return feature.isActiveFeature(feature.features.MultiProjects)
    },
    showAddMoneyBtn () {
      if (feature.isAnyActiveFeature(feature.features.PaymentGateway, feature.features.PaymentInvoice) && !partner.isPartnerName(partner.partners.MIRAN)) {
        return true
      } else {
        return false
      }
    },
  },
  created () {
    window.addEventListener('click', this.onClickHide)
  },
  beforeMount () {
    this.getCustomLogoUrl()
  },
  beforeUnmount () {
    window.removeEventListener('click', this.onClickHide)
  },
  methods: {
    switchOpen () {
      if (this.$store.state.menu.isOpen) {
        this.isOpen = !this.isOpen
      }
    },
    onClickHide (e) {
      if (!this.$el.contains(e.target)) {
        this.isOpen = false
      }
    },
    getCustomLogoUrl () {
      this.$store.dispatch(PROJECT_CUSTOM_LOGO_URL)
        .then((data) => {
          this.customLogoUrl = data.LogoBaseUrl
          this.logoLoaded = true
        })
    },
    getLogo (logoName) {
      if (this.defaultLogos.filter(x => x.name === logoName).length > 0) {
        return this.defaultLogos.find(x => x.name === logoName).path
      } else {
        return this.customLogoUrl + logoName
      }
    },
    projectIsActive (id) {
      return id === this.$store.state.project.current.Id
    },
    toAddMoney () {
      if (this.$route.name !== 'billing-add-money') {
        this.$router.push({ name: 'billing-add-money' })
      } else {
        this.$store.commit(COMMON_MOBILE_MENU, false)
      }
    },
    showSettingsIcon (project) {
      return !project.IsPersonal && this.projectIsActive(project.Id)
    },
    toSettings () {
      this.$router.push({ name: 'account-manage', hash: '#Project' })
      this.isOpen = false
    },
  },
}
</script>

<style lang="less" scoped>
.project-select {
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  color: var(--projects-color-text);
  transition: all var(--menu-transition);

  &-menu {
    position: absolute;
    top: 35px;
    left: 13px;
    z-index: 1;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 216px;
    max-height: 600;
    margin-top: -3px;
    overflow: hidden;
    background-color: var(--projects-color-bg-active);
    border: 1px solid var(--projects-color-border);
    border-radius: var(--border-radius);
    transition: all cubic-bezier(1, 0.01, 0.03, 0.95) var(--menu-transition);

    &.hidden {
      width: 34px;
    }

    &-ps {
      max-height: 225px;
    }

    &-list {
      display: flex;
      flex-direction: column;
    }

    &-create {
      display: flex;
      flex-direction: column;

      :deep(&-help) {
        svg {
          width: 20px;
          height: 20px;
        }
      }
    }

    &-list + &-create {
      margin-top: 10px;
      border-top: 1px solid var(--projects-color-border);
    }
  }

  &--menu-open {
    .project-item {
      padding: 0 20px;
    }
  }

  &--menu-open &-menu-create {
    flex-direction: row;
  }

  .mobile-add-money {
    display: none;
    color: unset;
  }

  &.mobile {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .project-item {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0 8px;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;

    &-settings-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;

      :deep(svg path) {
        transition: 0.2s;
      }

      &:hover {
        :deep(svg path) {
          fill: var(--color-primary);
        }
      }
    }

    &-selected {
      color: var(--color-primary);
      background-color: var(--projects-color-bg-hover);

      &-icon {
        & span {
          display: flex;
        }
      }
    }

    :deep(.vue-loading) {
      width: 20px;
      height: 20px;
    }

    &-current {
      position: relative;
      box-sizing: border-box;
      height: 32px;
      margin-left: 13px;
      overflow: unset;
      border: 2px solid transparent;

      & .project-item-name {
        color: var(--menu-color-text);
      }

      &.hidden {
        cursor: default;

        & .project-item-name,
        & .project-item-settings-icon {
          display: none;
        }

        &::after {
          position: absolute;
          top: 50%;
          left: 16px;
          display: block;
          width: 32px;
          height: 32px;
          content: "";
          border: 1px solid var(--color-border-secondary);
          border-radius: 50%;
          transform: translate(-50%, -50%);
        }
      }

      &-arrow {
        position: relative;
        display: block;
        transition: all var(--menu-transition);

        &.opened {
          top: 1px;
          transform: rotate(90deg);
        }
      }
    }

    &-logo {
      display: flex;
      flex-shrink: 0;
      width: 16px;
      height: 16px;
      border-radius: 100%;

      &.create-logo {
        display: inline-block;
        flex-shrink: 0;
        font-size: 17px;
        line-height: 15px;
        color: var(--color-light);
        text-align: center;
        background-color: var(--color-primary);
      }
    }

    &-name {
      flex: 1;
      margin-left: 15px;
      overflow: hidden;
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      color: var(--menu-project-select-color);
      text-overflow: ellipsis;

      &.create-name {
        font-size: 14px;
        font-weight: 500;
        color: var(--color-primary);
      }
    }

    &-settings {
      height: 24px;
      margin-left: 10px;
    }

    &:not(.project-item-current) {
      height: 32px;
      color: var(--color-disabled-secondary);
      text-decoration: none;
      cursor: pointer;

      &:hover {
        color: var(--color-primary);
        background-color: var(--projects-color-bg-hover);
      }
    }
  }

  .project-pending {
    padding: 10px 5px 0;
    margin: 0 10px;
    font-size: 12px;
    font-weight: 500;
    color: var(--color-disabled-secondary);
    border-bottom: 1px solid var(--color-disabled-primary);
  }

  &--open .project-item-current {
    width: 216px;
    min-width: 216px;
    margin-left: 13px;
    color: var(--projects-color-text-active);
    border: 2px solid var(--color-primary);
    border-radius: var(--border-radius);
  }
}

@media (max-width: 820px) {
  .project-select {
    &.mobile {
      width: 100%;
    }

    & .project-item-name {
      display: flex;
      width: max-content;
      margin-right: 12px;
    }

    & .project-item-current {
      width: 216px;
      margin-left: 0;
    }

    & .mobile-add-money {
      display: block;
      margin-right: 20px;
    }

    &-menu {
      left: 0;
    }
  }
}

@media (max-width: 600px) {
  .project-select {
    &.mobile {
      flex-direction: column-reverse;
      gap: 10px;
      align-items: flex-start;
      margin-top: 8px;
    }

    & .mobile-add-money {
      margin-left: 0;
    }

    &-menu {
      top: 77px;
    }
  }
}
</style>

