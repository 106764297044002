<template>
  <router-link-project
    ref="test"
    :to="to"
    class="layout-router-link"
    :class="{'router-link-active': activeClass}"
  >
    <span
      class="layout-router-link-icon"
      v-html="iconHtml"
    />
    <span
      v-if="!hideName"
      class="layout-router-link-name"
    >
      <span>{{ name }}</span>
      <span
        v-if="label"
        class="layout-router-link-label"
      >
        {{ label }}
      </span>

      <span
        v-if="notifyCounter"
        class="layout-router-link-counter"
      >
        {{ notifyCounter }}
      </span>
    </span>
  </router-link-project>
</template>
<script>
import { COMMON_MOBILE_MENU } from '@/store/mutations.type'

export default {
  props: {
    to: { type: Object, required: true },
    iconHtml: { type: String, required: false, default: null },
    hideName: { type: Boolean, default: false },
    name: { type: String, required: true },
    dirName: { type: String, default: '' },
    customActive: { type: Boolean, default: false },
    notifyCounter: { type: Number, default: 0 },
    label: { type: String, default: '' },
  },
  data () {
    return {
      activeClass: false,
    }
  },
  watch: {
    '$route' (to) {
      if (this.$store.state.menu.mobile) this.$store.commit(COMMON_MOBILE_MENU, false)
      this.isActive()
      if (!to.fullPath.includes(`/${this.dirName}/`)) {
        this.activeClass = false
      } else {
        this.activeClass = true
      }
    },
  },
  created () {
    this.isActive()
  },
  methods: {
    isActive () {
      if (document.location.pathname.includes(`/${this.dirName}/`) && this.customActive) {
        this.activeClass = true
      } else {
        this.activeClass = false
      }
    },
  },
}
</script>

<style lang="less" scoped>
.layout-router-link {
  position: relative;
  display: flex;
  align-items: center;
  height: 40px;
  padding-left: 30px;
  font-weight: 500;
  color: var(--menu-color-text);
  text-decoration: none;
  transition: all var(--menu-transition);

  &-counter {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 20px;
    height: 20px;
    padding: 3px 5px;
    margin-right: 10px;
    margin-left: 10px;
    font-size: 12px;
    line-height: 1.21;
    color: #fff;
    text-align: center;
    letter-spacing: 1px;
    background-color: #e95f4e;
    border-radius: 20px;
  }

  &-icon {
    display: flex;
    align-items: center;
    height: 20px;

    :deep(.color-fill) {
      fill: var(--menu-color-icon);
    }

    :deep(.color-stroke) {
      stroke: var(--menu-color-icon);
    }
  }

  &-name {
    display: flex;
    flex: 1;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-left: 15px;
    font-size: 14px;
    color: var(--menu-color-text);
  }

  &-label {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 43px;
    height: 19px;
    margin-left: 6px;
    font-size: 9px;
    color: #fff;
    letter-spacing: 0.7px;
    background-color: var(--color-primary);
    border-radius: 4px;
  }

  &.router-link-active,
  &:hover {
    color: var(--color-base);
    background-color: var(--menu-color-bg-active);

    :deep(.color-fill) {
      fill: var(--color-base);
    }
  }
}
</style>
