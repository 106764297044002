import { DOCUMENT_FLOW_GET, DOCUMENT_FLOW_UPDATE, DOCUMENT_GET_VAT_RATE } from '../actions.type'

import ApiService from '@/api'

const state = {
}

// getters
const getters = {
}
// actions
const actions = {
  [DOCUMENT_FLOW_GET] () {
    return ApiService.get('/app-api/documentflow/json')
      .then(({ data }) => {
        return data
      })
  },
  [DOCUMENT_FLOW_UPDATE] (context, model) {
    return ApiService.put('/app-api/documentflow', model)
      .then(({ data }) => {
        return data
      })
  },
  [DOCUMENT_GET_VAT_RATE] (context, model) {
    return ApiService.query(`/app-api/documentflow/vat?country=${model.countryCode}&IsIndividual=${model.isIndividual}`)
      .then(({ data }) => {
        return data
      })
  },
}

// mutations
const mutations = {
}

export default {
  state,
  getters,
  actions,
  mutations,
}
