<template>
  <div
    class="vue-base-input"
    :class="[
      { 'focus': inFocus },
      { 'small': small },
    ]"
    :style="{ 'width': width }"
  >
    <div class="vue-base-input-inner">
      <span
        v-show="showPlaceholder"
        class="vue-base-input-placeholder"
        :class="[(amountLength >= 28) ? 'vue-base-input-placeholder--small' : '']"
      >{{ placeholder }}</span>
      <span
        v-if="searchIcon"
        class="vue-base-input-input-search-icon"
        v-html="icons.ICON_SEARCH"
      />
      <input
        ref="input"
        v-model="innerValue"
        class="vue-base-input-input"
        :disabled="disabled"
        :readonly="disabled"
        :placeholder="placeholder"
        :type="hidePassword ? inputTypeCurrent : type || 'text'"
        :min="min"
        :max="max"
        :class="[{'error': error}, {'with-placeholder': showPlaceholder }, {'big': full}, {'with-search': searchIcon}]"
        :name="name"
        v-bind="dataProps"
        @input="inputHandler($event.target.value)"
        @keyup.enter="onEnter"
        @keyup.down="onDown"
        @keyup.up="onUp"
        @blur="onBlur"
        @keydown="keydownHandler($event)"
        @keyup="$emit('keyup')"
      >
      <button
        v-if="hidePassword"
        class="eye-password"
        @click="showPasswordToggle"
        v-html="currentEyeIcon"
      />
      <span
        v-if="hintShowArrow && innerValue.length >= hintLength && hints && hints.length"
        class="vue-base-input-arrow"
        :class="{'vue-base-input-arrow--open': inFocus}"
        @click="setFocus"
      />
      <div
        v-show="showHints"
        class="vue-base-input-list-hints"
        :class="[{'error': error}]"
        :style="{ top: small ? '37px' : topDistanceCalc + 'px' }"
      >
        <PerfectScrollbar
          ref="listHints"
          class="vue-base-input-list-hints-ps"
          :options="{wheelPropagation: false}"
        >
          <div
            v-for="(hint, index) in filterHints"
            :key="hint[hintKey]"
            ref="hintItems"
            class="vue-base-input-list-hints-ps-item"
            :class="{'vue-base-input-list-hints-ps-item--selected': index === selectedHintIndex}"
            @mousedown="setHint(hint)"
          >
            {{ hint[hintKey] }}
          </div>
        </PerfectScrollbar>
      </div>
    </div>
    <div
      v-show="error"
      class="vue-base-input-error-label"
    >
      <div
        v-if="clientError.length"
        class="errors"
      >
        <span
          v-for="(item, idx) in clientError"
          :key="idx"
        >
          {{ item }}
        </span>
      </div>
      <div
        v-else-if="serverError.length"
        class="errors"
      >
        <span
          v-for="(item, idx) in serverError"
          :key="idx"
        >
          {{ item }}
        </span>
      </div>
      <div v-else-if="(errorMessage || required)">
        {{ errorMessage || required }}
      </div>
    </div>
  </div>
</template>

<script>
import { ICON_EYE_CLOSED, ICON_EYE_OPENED, ICON_SEARCH } from '@/svg/icons'

export default {
  name: 'BaseInput',
  props: {
    modelValue: { type: [String, Number], default: '' },
    placeholder: { type: String, default: '' },
    required: { type: String, default: null },
    serverError: { type: Array, default: () => [] },
    disabled: { type: Boolean, default: false },
    password: { type: Boolean, default: false },
    hidePassword: { type: Boolean, default: false },
    small: { type: Boolean, default: false },
    full: { type: Boolean, default: false },
    hints: { type: Array, default: () => [] },
    hintKey: { type: String, default: 'key' },
    hintValue: { type: String, default: 'value' },
    hintLength: { type: Number, default: 1 },
    hintMax: { type: Number, default: null },
    hintShowArrow: { type: Boolean, default: true },
    type: { type: String, default: 'text' },
    min: { type: Number, default: null },
    max: { type: Number, default: null },
    errorMessage: { type: String, default: null },
    name: { type: String, default: null },
    dataProps: { type: Object, default: null },
    amountLength: { type: Number, default: null },
    clientError: { type: Array, default: () => [] },
    width: { type: String, default: '' },
    topDistance: { type: [String, Number], default: '' },
    searchIcon: { type: Boolean, default: false },
  },
  emits: ['keyup', 'focused', 'update:modelValue', 'keydown', 'hintSelect', 'blur', 'enter', 'change'],
  data () {
    return {
      inFocus: false,
      inFocusDelay: false,
      innerValue: this.modelValue !== null ? this.modelValue : '',
      selectedHintIndex: -1,
      serverErrorData: [],
      clientErrorData: [],
      inputTypeCurrent: 'text',
      icons: {
        ICON_EYE_OPENED,
        ICON_EYE_CLOSED,
        ICON_SEARCH,
      },
      showFullPassword: false,
    }
  },
  computed: {
    topDistanceCalc () {
      if (this.topDistance) {
        return this.topDistance + 3
      } else {
        return '43'
      }
    },
    error () {
      return this.errorMessage || (this.required && (typeof this.modelValue === 'number' ? this.modelValue === null : !this.modelValue)) || this.clientErrorData.length || this.serverErrorData.length
    },
    showPlaceholder () {
      return this.full && this.modelValue != null && this.modelValue.toString().length > 0
    },
    showHints () {
      return this.inFocus && this.filterHints.length > 0 && this.modelValue.toString().length >= this.hintLength
    },
    filterHints () {
      let hints = this.hints.filter(x => x[this.hintKey].toString().toLowerCase().includes(this.modelValue.toString().toLowerCase()) || x[this.hintValue].toString().toLowerCase().includes(this.modelValue.toString().toLowerCase()))

      if (this.hintMax) {
        return hints.slice(0, this.hintMax)
      }
      return hints
    },
    valueLabel () {
      if (this.hints.length > 0) {
        let hint = this.hints.find(x => x[this.hintValue].toString().toLowerCase() === this.modelValue.toString().toLowerCase())
        if (hint) { return hint[this.hintKey] }
      }

      return this.modelValue
    },
    currentEyeIcon () {
      return !this.showFullPassword ? this.icons.ICON_EYE_OPENED : this.icons.ICON_EYE_CLOSED
    },
  },
  watch: {
    modelValue: function (newValue) {
      this.innerValue = newValue !== null ? newValue : ''
      this.serverErrorData = []
      this.inputHandler(this.innerValue)
    },
    filterHints: function () {
      this.selectedHintIndex = -1
    },
    inFocus: function () {
      this.$emit('focused', this.inFocus)
    },
    serverError: function () {
      this.serverErrorData = this.serverError
    },
    clientError: function () {
      this.clientErrorData = this.clientError
    },
  },
  updated () {
    if (this.selectedHintIndex !== -1) {
      let el = this.$refs.hintItems.find(x => x.classList.contains('vue-base-input-list-hints-ps-item--selected'))
      if (el) {
        this.$refs.listHints.$el.scroll({
          top: Math.max(0, el.offsetTop - 72),
          behavior: 'smooth',
        })
      }
    }
  },
  created () {
    document.addEventListener('focusin', this.focusChanged)
  },
  beforeUnmount () {
    document.removeEventListener('focusin', this.focusChanged)
  },
  methods: {
    inputHandler (value) {
      if (this.hidePassword) {
        if (value.length > 0) {
          this.inputTypeCurrent = 'password'
        } else {
          this.inputTypeCurrent = 'text'
        }
      }
      if (this.hints.length > 0) {
        let hint = this.hints.find(x => x[this.hintKey].toString().toLowerCase() === value.toLowerCase() || x[this.hintValue].toString().toLowerCase() === value.toLowerCase())
        if (hint) {
          this.$emit('update:modelValue', hint[this.hintValue])
        } else {
          this.$emit('update:modelValue', value)
        }
      } else if ((this.type === 'number' && !isNaN(parseFloat(value))) || (this.type === 'custom' && !isNaN(parseFloat(value)))) {
        value = parseFloat(value)
        if (this.min !== null && value < this.min) {
          value = this.min
          this.innerValue = value
        }
        if (this.max !== null && value > this.max) {
          value = this.max
          this.innerValue = value
        }
        this.$emit('update:modelValue', value)
      } else {
        this.$emit('update:modelValue', value)
      }
    },
    keydownHandler (value) {
      this.$emit('keydown', value)
      if (this.type === 'number' || this.type === 'custom') {
        if (/[^0-9]/i.test(value.key) && value.key !== 'Backspace' && value.key !== 'ArrowLeft' && value.key !== 'ArrowRight' && value.key !== 'Delete' && value.key !== 'Tab') {
          value.preventDefault()
          return false
        } else {
          return true
        }
      } else if (this.type === 'ssl-number') {
        if (/[^0-9+-.]/i.test(value.key) && value.key !== 'Backspace' && value.key !== 'ArrowLeft' && value.key !== 'ArrowRight' && value.key !== 'Delete' && value.key !== 'Tab') {
          value.preventDefault()
          return false
        } else {
          if (this.innerValue.length > 0 && value.key === '+') {
            value.preventDefault()
            return false
          }
          if (this.innerValue.length < 1) {
            if (value.key === '-' || value.key === '.') {
              value.preventDefault()
              return false
            }
          }
          return true
        }
      } else if (this.type === 'float-number') {
        if (Number(this.modelValue) >= Number(this.max) && value.key !== 'Backspace') {
          value.preventDefault()
          return false
        } else if (
          (!/^[0-9.]+$/i.test(value.key) &&
          value.key !== 'Backspace' &&
          value.key !== 'ArrowLeft' &&
          value.key !== 'ArrowRight' &&
          value.key !== 'Delete')
        ) {
          value.preventDefault()
          return false
        } else {
          return true
        }
      }
    },
    focusChanged (event) {
      this.inFocus = this.$refs.input === event.target
    },
    focusDelay () {
      this.inFocusDelay = true
      setTimeout(() => {
        this.inFocusDelay = false
      }, 300)
    },
    setHint (hint) {
      this.inputHandler(hint[this.hintKey])
      this.$emit('hintSelect', hint[this.hintKey])
    },
    onBlur () {
      this.inFocus = false
      this.selectedHintIndex = -1
      this.focusDelay()
      this.$emit('blur')
      this.$emit('change', this.innerValue)
    },
    setFocus () {
      if (!this.inFocusDelay) {
        this.$refs.input.focus()
      }
    },
    onEnter () {
      if (this.selectedHintIndex === -1) {
        this.$emit('change', this.innerValue)
        this.$emit('enter')
      } else {
        this.inputHandler(this.filterHints[this.selectedHintIndex][this.hintKey])
        this.$refs.input.blur()
        this.$emit('change', this.innerValue)
        this.$emit('enter')
      }
    },
    onUp () {
      if (!this.filterHints.length) {
        return
      }

      if (this.selectedHintIndex - 1 >= 0) {
        this.selectedHintIndex--
      } else {
        this.selectedHintIndex = this.filterHints.length - 1
      }
    },
    onDown () {
      if (!this.filterHints.length) {
        return
      }

      if (this.selectedHintIndex + 1 < this.filterHints.length) {
        this.selectedHintIndex++
      } else {
        this.selectedHintIndex = 0
      }
    },
    showPasswordToggle () {
      if (this.inputTypeCurrent === 'text') {
        this.inputTypeCurrent = 'password'
        this.showFullPassword = false
      } else {
        this.inputTypeCurrent = 'text'
        this.showFullPassword = true
      }
    },
  },
}
</script>

<style lang="less" scoped>
.vue-base-input {
  &-inner {
    position: relative;
    display: flex;
    flex-direction: column;
  }

  &-placeholder {
    position: absolute;
    font-size: 11px;
    font-weight: 500;
    transform: translate(17px, 10px);

    &--small {
      top: 2px;
    }
  }

  &.small {
    & .vue-base-input-input {
      height: 32px;
    }
  }

  & .eye-password {
    position: absolute;
    top: 11px;
    right: 11px;
    cursor: pointer;
    background-color: transparent;
    border: none;

    :deep(svg) {
      &:hover path,
      &:hover circle {
        stroke: var(--color-primary);
        transition: 0.2s;
      }
    }
  }

  &-input {
    width: 100%;
    height: 40px;
    padding: 15px;
    font: inherit;
    font-size: 14px;
    font-weight: 400;
    color: var(--color-base);
    border: 1px solid var(--color-border);
    border-radius: var(--border-radius);
    outline: none;

    &.with-search {
      padding-left: 35px !important;
      text-overflow: ellipsis;
    }

    &-search-icon {
      position: absolute;
      top: 8px;
      left: 11px;
      display: flex;
      width: max-content;
      height: max-content;

      :deep(svg path) {
        fill: var(--color-base);
      }
    }

    &.big {
      height: 50px;
    }

    &:hover {
      border: 1px solid var(--color-disabled-secondary);
    }

    &.error {
      border: 1px solid var(--color-danger);

      &:focus {
        border: 2px solid var(--color-danger);
      }
    }

    &:active,
    &:focus {
      padding: 14px;
      border: 2px solid var(--color-primary);
    }

    &:disabled,
    &[readonly] {
      color: var(--color-disabled-secondary);
      background-color: var(--color-background-secondary);
      opacity: 1;
      -webkit-text-fill-color: var(--color-disabled-secondary);
    }

    &.with-placeholder {
      padding-top: 30px;
    }
  }

  &-arrow {
    &::after {
      position: absolute;
      top: 46%;
      right: 20px;
      display: inline-block;
      width: 7px;
      height: 7px;
      content: " ";
      border-color: var(--color-disabled-secondary);
      border-style: none solid solid none;
      border-width: 1.5px;
      transition: all 0.2s;
      transform: translateY(-3px) rotate(45deg);
    }

    &--open {
      &::after {
        transform: translateY(1px) rotate(225deg);
      }
    }
  }

  &-list-hints {
    position: absolute;
    top: 100%;
    z-index: 3;
    width: 100%;
    padding: 5px 0;
    margin-top: -5px;
    background-color: var(--color-light);
    border: 2px solid var(--color-primary);
    border-top: none;
    border-radius: var(--border-radius);
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    &.error {
      border: 2px solid var(--color-danger);
      border-top: none;
    }

    &-ps {
      max-height: 180px;

      &-item {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 10px 20px;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;

        & .logo {
          max-width: 35px;
          margin-right: 10px;
        }

        &:hover,
        &--selected {
          color: var(--color-primary);
          background-color: var(--color-background);
        }
      }
    }
  }

  &.focus {
    .vue-base-input-placeholder {
      color: var(--color-primary);
    }
  }

  &-error-label {
    width: 100%;
    margin-top: 5px;
    font-size: 12px;
    color: var(--color-danger);
    letter-spacing: 0.48px;

    & .errors {
      display: flex;
      flex-direction: column;
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

@media (max-width: 530px) {
  .vue-base-input-input {
    font-size: 12px;
  }
}
</style>
