export const PROJECT_FEATURE_IS_ACTIVE = 'projectFeatureIsActive'
export const FINANCES_FEATURE_IS_ACTIVE = 'financesFeatureIsActive'

export const SIMPLEONE_GET_LINK_PORTAL = 'supportSimpleOneGetLink'
export const SIMPLEONE_GET_LINK_SUPPORT = 'supportSimpleOneGetLinkFull'
export const SIMPLEONE_GET_LINK_PORTAL_ESSENCE = 'supportSimpleOneGetLinkEssense'

export const VSTACK_SERVERS_IS_EXPIRED = 'vstackServersIsExpired'
export const VSTACK_NETWORKS_IS_EXPIRED = 'vstackNetworksIsExpired'

export const MOBILE_MENU_OPENED = 'mobileMenuOpened'
