<template>
  <router-link-project
    class="balance"
    :to="{ name: 'billing' }"
  >
    <span class="balance-value">
      {{ formatCurrency(toFixedNum($store.state.project.current.Balance, 2)) }}
    </span>
    <span
      v-if="enoughValue"
      class="balance-enough"
    >
      <i18n-t
        keypath="billing.shared.balance.enough"
        tag="span"
      >
        <template #value>
          {{ enoughValue }}
        </template>
      </i18n-t>
    </span>
  </router-link-project>
</template>

<script>
import { toFixedNum } from '@/lib/numbers'
import formatCurrency from '@/lib/formatCurrency'
export default {
  computed: {
    enoughValue () {
      if (this.$store.state.project.current.IsPostPaid || !this.$store.state.project.current.MinutesLeftBeforeLockdown) {
        return null
      }

      let days = Math.trunc(this.$store.state.project.current.MinutesLeftBeforeLockdown / 60 / 24)

      let hours = Math.trunc(this.$store.state.project.current.MinutesLeftBeforeLockdown / 60 % 24)

      let minutes = Math.trunc(this.$store.state.project.current.MinutesLeftBeforeLockdown % 60)

      if (days > 5 * 365) {
        return null
      }

      let value = ''

      if (days >= 1) {
        value = value + this.$tc('billing.shared.balance.time.days', days)
      }

      if (hours >= 1 && days < 3) {
        value = value.length ? value + ' ' : value
        value = value + this.$tc('billing.shared.balance.time.hours', hours)
      }

      if (hours < 1 && days < 1) {
        value = value.length ? value + ' ' : value
        value = value + ' ' + this.$tc('billing.shared.balance.time.minutes', minutes)
      }

      return value
    },
  },
  methods: {
    toFixedNum,
    formatCurrency,
  },
}
</script>

<style lang="less" scoped>
.balance {
  display: flex;

  &-value {
    font-size: 14px;
    font-weight: 500;
    color: var(--color-base);
  }

  &-enough {
    margin-left: 8px;
    font-size: 14px;
    font-weight: 400;
    color: var(--color-disabled-secondary);
  }
}

.project-balance-mobile {
  border-radius: var(--border-radius);
}

@media (max-width: 820px) {
  .balance-enough {
    display: none;
  }
}

@media (max-width: 820px) {
  .project-balance-mobile .balance-enough {
    display: unset;
  }
}

@media (max-width: 600px) {
  .project-balance-mobile {
    margin-bottom: 16px;
    margin-left: 7px;
  }
}
</style>
